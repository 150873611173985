import { animate, state, style, transition, trigger } from '@angular/animations';
import { BooleanInput } from '@angular/cdk/coercion';
import { CdkConnectedOverlay, CdkOverlayOrigin, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  TemplateRef,
  viewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { environment } from '@environments/environment';
import { DIALOG_GLOBAL_CONFIG, DIALOG_HANDLER } from '@shared/dialog/dialog-tokens';
import { DialogChangeEvent } from '@shared/dialog/types/dialog-change-event.type';
import { DialogHandler } from '@shared/dialog/types/dialog-handler.type';
import { uniqueId } from 'lodash';
import { Observable, Subject } from 'rxjs';

export type DialogModalContext = Record<string, any>;

export interface IDialogModalConfig extends OverlayConfig {
  name?: string;
  hideOnOverlayClick?: boolean;
  scrollTo?: string | ElementRef | HTMLElement;
  autoOpen?: boolean;
  showClose?: boolean;
  context?: DialogModalContext;
}

@Component({
  standalone: true,
  selector: 'dialog-modal',
  animations: [
    trigger('dialogShow', [
      state(
        'hidden',
        style({
          opacity: 0,
          transform: 'scale(0.8)'
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'scale(1)'
        })
      ),
      transition('hidden <=> visible', animate(`${environment.transitionDuration}ms ease-in-out`))
    ])
  ],
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIcon, MatIconButton, CdkOverlayOrigin, CdkConnectedOverlay]
})
export class DialogModalComponent implements AfterViewInit, OnInit, OnDestroy {
  @HostBinding('class.dialog_source') readonly hostClass = true;

  private __dialogHandler = inject<DialogHandler>(DIALOG_HANDLER);
  private __hostRef = inject(ElementRef);
  private __destroyRef = inject(DestroyRef);
  private __templateRef = viewChild<TemplateRef<any>>('templateRef');
  private __viewContainerRef = inject(ViewContainerRef);
  private __overlayService = inject(Overlay);
  private __globalConfig = inject(DIALOG_GLOBAL_CONFIG);
  private __initialConfig = this.__createInitialConfig();
  readonly config = input<IDialogModalConfig, IDialogModalConfig>(this.__initialConfig, {
    transform: (config) => {
      return Object.assign({}, this.__initialConfig, config || {}, {
        backdropClass: [
          'dialog__overlay',
          ...(config?.backdropClass
            ? typeof config?.backdropClass === 'string'
              ? [config?.backdropClass]
              : config?.backdropClass || []
            : [])
        ],
        panelClass: [
          'dialog__panel',
          ...(config?.panelClass ? (typeof config?.panelClass === 'string' ? [config?.panelClass] : config?.panelClass || []) : [])
        ]
      });
    }
  });
  private __overlayRef = this.__overlayService.create({
    ...this.config(),
    backdropClass: this.config().backdropClass,
    panelClass: this.config().panelClass,
    positionStrategy: this.__overlayService.position().global().centerHorizontally().centerVertically()
  });
  protected _dialogCssClass = signal<string[]>([]);
  protected _showOverlay = signal(false);
  readonly icon = input<'error' | 'warning' | 'success' | 'question'>(undefined);
  readonly titleAlign = input<'left' | 'center' | 'right' | 'justify'>(undefined);
  readonly contentAlign = input<'left' | 'center' | 'right' | 'justify'>(undefined);
  readonly buttonsAlign = input<'left' | 'center' | 'space-between' | 'right'>(undefined);
  readonly title = input<TemplateRef<Component>>(undefined);
  readonly content = input<TemplateRef<Component>>(undefined);
  readonly buttons = input<TemplateRef<Component>>(undefined);
  readonly scrollTo = input<string>(undefined);
  readonly showClose = input<boolean, BooleanInput>(false, {
    transform: (value) => value === '' || !!value
  });
  readonly autoOpen = input<boolean, BooleanInput>(false, {
    transform: (value) => value === '' || !!value
  });
  protected _hasShowClose = computed(() => {
    return this.showClose() || !!this.config()?.showClose;
  });
  protected _whenClose$ = new Subject<void>();
  @Output() readonly whenClose = this._whenClose$.asObservable();
  private __statusChange$ = new Subject<DialogChangeEvent>();
  @Output() readonly statusChange = this.__statusChange$.asObservable();

  protected _context = signal<DialogModalContext>(undefined);

  constructor(@Attribute('class') public classList: string) {
    this.classList && this._dialogCssClass.set(this.classList.split(' ').map((className) => className.trim()));
  }

  get context(): DialogModalContext {
    return this._context();
  }

  get opened(): boolean {
    return this._showOverlay();
  }

  get name(): string {
    return this.config().name;
  }

  get isAutoOpen(): boolean {
    return this.autoOpen() || !!this.config()?.autoOpen;
  }

  get hasShowClose(): boolean {
    return this._hasShowClose();
  }

  ngAfterViewInit(): void {
    this.__hostRef.nativeElement.setAttribute('aria-dialog', this.name);
    this.__dialogHandler.register(this);

    this.__overlayRef
      .backdropClick()
      .pipe(takeUntilDestroyed(this.__destroyRef))
      .subscribe(() => !!this.config()?.hideOnOverlayClick && this._onClose());
  }

  ngOnInit(): void {
    this.isAutoOpen && this.show();
  }

  ngOnDestroy(): void {
    this.__dialogHandler.unregister(this);
  }

  private __createInitialConfig(): IDialogModalConfig {
    return Object.assign({}, this.__globalConfig || {}, {
      name: uniqueId(),
      backdropClass: ['dialog__overlay'],
      panelClass: ['dialog__panel']
    });
  }

  protected _animationFinish({
    fromState,
    toState,
    phaseName
  }: {
    fromState: 'visible' | 'hidden' | string;
    toState: 'visible' | 'hidden' | 'void' | string;
    phaseName: 'start' | 'done' | string;
  }): void {
    if (phaseName === 'start') {
      if (fromState === 'hidden' && toState === 'visible') {
        // Before open
        this.__emitEvent({ beforeOpen: true, context: this._context() });
      } else if (fromState === 'visible' && toState === 'hidden') {
        // Before close
        this.__emitEvent({ beforeClose: true, context: this._context() });
      }
    } else if (phaseName === 'done') {
      if (fromState === 'hidden' && toState === 'visible') {
        // After open
        this.__performScrollTo();
        this.__emitEvent({ afterOpen: true, context: this._context() });
      } else if (fromState === 'visible' && toState === 'hidden') {
        // After close
        this.__emitEvent({ afterClose: true, context: this._context() });
        this._close();
      }
    }
  }

  protected _open(): void {
    const portal = new TemplatePortal(this.__templateRef(), this.__viewContainerRef);
    this.__overlayRef.attach(portal);
  }

  protected _close(): void {
    this.__overlayRef.detach();
  }

  protected _onClose(): void {
    if (this._whenClose$.observed) this._whenClose$.next();
    else this.hide();
  }

  private __emitEvent(partialEvent: {
    beforeOpen?: boolean;
    afterOpen?: boolean;
    beforeClose?: boolean;
    afterClose?: boolean;
    context: DialogModalContext;
  }): void {
    const opened =
      ('afterOpen' in partialEvent && !!partialEvent.afterOpen) || ('beforeClose' in partialEvent && !!partialEvent.beforeClose);
    const closed =
      ('afterClose' in partialEvent && !!partialEvent.afterClose) || ('beforeOpen' in partialEvent && !!partialEvent.beforeOpen);
    const event = { opened, closed, ...partialEvent };

    this.__statusChange$.next(event);

    if (partialEvent?.context && 'dialogChange' in partialEvent.context && typeof partialEvent.context.dialogChange === 'function') {
      this._context().dialogChange(event);
    }
  }

  show(options?: Pick<IDialogModalConfig, 'context'>): Observable<DialogChangeEvent> {
    this._context.set(options?.context || this._context || undefined);
    this._open();
    this._showOverlay.set(true);
    return this.statusChange;
  }

  hide(options?: Pick<IDialogModalConfig, 'context'>): Observable<DialogChangeEvent> {
    this._context.set(options?.context || this._context || undefined);
    this._showOverlay.set(false);
    return this.statusChange;
  }

  toggle(options?: Pick<IDialogModalConfig, 'context'>): Observable<DialogChangeEvent> {
    this._context.set(options?.context || this._context || undefined);
    !this.opened ? this.show() : this.hide();
    return this.statusChange;
  }

  private __performScrollTo(): void {
    let scrollRef: HTMLElement;

    if (this.config().scrollTo) {
      const scrollTo = this.config().scrollTo;
      if (typeof scrollTo === 'string') {
        scrollRef = document.getElementById(scrollTo);
      } else if (scrollTo instanceof ElementRef) {
        scrollRef = scrollTo.nativeElement;
      } else {
        scrollRef = scrollTo;
      }
      scrollTo && scrollRef.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
