<ng-template #templateRef>
  <div
    class="dialog__container"
    [ngClass]="_dialogCssClass()"
    [@dialogShow]=""
    [@dialogShow]="_showOverlay() ? 'visible' : 'hidden'"
    (@dialogShow.start)="_animationFinish($event)"
    (@dialogShow.done)="_animationFinish($event)"
  >
    @if (!!icon() && !_context()?.icon) {
      <div class="icon">
        <img [src]="'assets/icons/' + icon() + '.svg'" />
      </div>
    }
    @if (!!_context()?.icon) {
      <div class="icon">
        <img [src]="'assets/icons/' + _context().icon + '.svg'" />
      </div>
    }

    @if (!!title()) {
      <div class="title" [ngClass]="[titleAlign ? 'title__' + titleAlign() : '']">
        <ng-container [ngTemplateOutlet]="title()" [ngTemplateOutletContext]="_context()"></ng-container>
      </div>
    }
    <div class="title" [ngClass]="[titleAlign() ? 'title__' + titleAlign() : '']">
      <ng-content select="[title]"></ng-content>
    </div>

    @if (!!content()) {
      <div class="content" [ngClass]="[contentAlign() ? 'title__' + contentAlign() : '']">
        <ng-container [ngTemplateOutlet]="content()" [ngTemplateOutletContext]="_context()"></ng-container>
      </div>
    }
    <div class="content" [ngClass]="[contentAlign() ? 'title__' + contentAlign() : '']">
      <ng-content select="[content]"></ng-content>
    </div>

    @if (!!buttons()) {
      <div class="buttons" [ngClass]="[buttonsAlign ? 'title__' + buttonsAlign() : '']">
        <ng-container [ngTemplateOutlet]="buttons()" [ngTemplateOutletContext]="_context()"></ng-container>
      </div>
    }
    <div class="buttons" [ngClass]="[buttonsAlign() ? 'title__' + buttonsAlign() : '']">
      <ng-content select="[buttons]"></ng-content>
    </div>

    <ng-content select="app-batch-actions-bar"></ng-content>

    @if (_hasShowClose()) {
      <button type="button" mat-icon-button class="dialog__close rotate-animation-hover" (click)="_onClose()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
</ng-template>
